<template>
    <section id="index_ads" ref="index_ads">
        <div class="inner w1200">
            <div class="text txt-right">
                <h2 class="txt-bold">
                    New Ads
                </h2>
                <h5 class="zn-slogan">
                    敢求新 敢求異．領先開創 新廣告
                </h5>
            </div>
            <div class="itembox">
                <div class="item" v-bind:class="{active : ifItemShow}">
                    <div class="item_text">
                        <h4 class="txt-bold">
                            <span class="txt-red">A</span>DVERTISEMENT
                        </h4>
                        <p>
                            廣告
                        </p>
                    </div>
                </div>
                <div class="item" v-bind:class="{active : ifItemShow}">
                    <div class="item_text">
                        <h4 class="txt-bold">
                            <span class="txt-yellow">D</span>IGITAL<br> MARKETING
                        </h4>
                        <p>
                            數位活動
                        </p>
                    </div>
                </div>
                <div class="item" v-bind:class="{active : ifItemShow}">
                    <div class="item_text">
                        <h4 class="txt-bold">
                            <span class="txt-green">S</span>YSTEM
                        </h4>
                        <p>
                            系統建置
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <p id="ads_bg" class="txt-bold" v-bind:style="{transform: 'translateX(-' + bgMove + '%)'}">
            ADS
        </p>
    </section>
</template>

<script>
export default {
    name: 'indexAds',
    data: function() {
        return {
            scrollDistence: window.scrollY,
            screenHeight: window.innerHeight,
            elOffestTop: Number,
            elHeight: Number,
            bgMove: Number,
            ifItemShow: false
        }
    },
    methods: {
        listenScrollDistence: function(){
            this.scrollDistence = window.scrollY;
        },
        bgMoveCount: function(){
            if((this.scrollDistence - this.elOffestTop)/this.elHeight <= 1){
              this.bgMove = (this.scrollDistence/this.elHeight)*100/15;  
            }
        },
        scrollToShow: function(){
            if(this.scrollDistence >= this.elOffestTop-this.screenHeight/2){
                this.ifItemShow = true;
            }
            if(this.scrollDistence <= this.elOffestTop-this.screenHeight){
                this.ifItemShow = false;
            }
        }
    },
    watch: {
        scrollDistence: function(){
            this.bgMoveCount();
            this.scrollToShow();
        }
    },
    created: function(){
        window.addEventListener('scroll', this.listenScrollDistence, true);
    },
    mounted: function(){
        this.elHeight = this.$refs.index_ads.offsetHeight;
        this.elOffestTop= this.$refs.index_ads.offsetTop;
        this.scrollToShow();
    },
    destroyed: function(){
        window.removeEventListener('scroll', this.listenScrollDistence, true);
    }
}
</script>

<style lang="scss">
#index_ads{
    padding: 8% 0 16%;
    position: relative;
    overflow: hidden;
    .inner{
        position: relative;
        z-index: 1;
    }
    .zn-slogan{
        margin-top: 10px;
        letter-spacing: 4.5px;
    }
    .itembox{
        margin-top: 10%;
        display: flex;
        justify-content: space-between;
        .item{
            position: relative;
            display: flex;
            opacity: 0;
            transform: translateX(-30px);
            &::before{
                content: '';
                margin-right: 20px;
                width: 40px;
                height: 3px;
                display: inline-block;
                position: relative;
                top: 16px;
            }
            &:nth-child(1){
                &::before{ background-color: $color-red; }
                &.active{
                    animation: item_show .3s both;
                }
            }
            &:nth-child(2){
                &::before{ background-color: $color-yellow; }
                &.active{
                    animation: item_show .3s .3s both;
                }
            }
            &:nth-child(3){
                &::before{ background-color: $color-green; }
                &.active{
                    animation: item_show .3s .6s both;
                }
            }
            h4{
                min-height: 70px;
                margin-bottom: 10px;
            }
        }
    }
}
@keyframes item_show{
    0%{
        opacity: 0;
        transform: translateX(-30px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
#ads_bg{
    color: $color-light;
    font-size: 36vw;
    line-height: .7;
    position: absolute;
    top: 40px;
    left: -8%;
    pointer-events: none;
    user-select: none;
    transition: 1s linear;
}

@media screen and (max-width: 1023px){
    #index_ads{
        .text{
            text-align: center;
        }
        .itembox{
            flex-wrap: wrap;
            justify-content: flex-start;
            .item{
                width: 100%;
                margin-bottom: 40px;
                h4{
                    min-height: auto;
                }
            }
        }
    }
}
@media screen and (max-width: 768px){
    #ads_bg{
        font-size: 55vw;
        top: 20%;
    }
}
@media screen and (max-width: 480px){
    #index_ads{
        .text{
            text-align: left;
        }
        padding: 12% 0;
    }
}
</style>