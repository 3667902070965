<template>
    <section id="index_giants">
        <div class="text txt-center">
            <h2 class="txt-white txt-bold">
                STANDING ON<br> 
                THE HEADS OF <span class="highlight txt-red">GIANTS</span>
            </h2>
            <h5 class="zn-slogan txt-white">
                看得更遠才能主掌未來，要站就站在巨人頭頂！
            </h5>
        </div>
    </section>
</template>

<script>
export default {
    name: 'indexGiants'
}
</script>

<style lang="scss">
    #index_giants{
        width: 100%;
        padding-bottom: 450px;
        background-image: url('~@/assets/img/index/02_background.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        position: relative;
        box-shadow: inset 0 0 30px rgba(0,0,0,.5);
        .text{
            width: 100%;
            padding: 5%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            .highlight{
                position: relative;
                &::before{
                    content: '';
                    width: 100%;
                    height: 35%;
                    position: absolute;
                    bottom: 0;
                    left: -5%;
                    background-color: $color-red;
                    opacity: .3;
                    z-index: -1;
                }
            }
            .zn-slogan{
                margin-top: 30px;
                letter-spacing: 4.5px;
            }
        }
    }
    @media screen and (max-width: 1023px){
        #index_giants{
            padding-bottom: 350px;
            background-attachment: inherit;
        }
    }
</style>