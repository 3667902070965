<template>
    <div id="app">
        <router-view/>
        <publicFooter/>
    </div>
</template>

<script>
// @ is an alias to /src
import publicFooter from '@/components/public/Footer.vue'
export default {
    name: 'App',
    components: {
        publicFooter
    },
    created() {
        console.log(this.$route.query);
        localStorage.setItem('source', JSON.stringify(this.$route.query));
    },
}
</script>