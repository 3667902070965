<template>
    <section id="footer">
        <div class="inner w1200">
            <div class="left">
                <div class="loc">
                    <p class="loc_item">
                        <span class="txt-grey">台灣 | </span>
                        <span class="txt-grey">上海 | </span>
                        <span class="txt-grey">成都 | </span>
                        <span class="txt-grey">雅加達</span>
                    </p> 
                    <p
                        @click="openMail()"
                        href="mailto:service@howdesign.com.tw"
                        class="loc_item">
                            <span class="txt-grey">Email </span>service@howdesign.com.tw
                    </p>    
                    <p
                        @click="openPhone()"
                        href="tel:0223311966"
                        class="loc_item">
                            <span class="txt-grey">Tel </span>02-2331-1966
                    </p>                 
                </div>
                <div class="allrights">
                    <small>
                        怎漾集團版權所有 © 2020 HOWGROUP All rights reserved.
                    </small>
                    <small class="allrights_links">
                        <!-- <a href="">隱私權政策</a>
                        <a href="">服務條款</a> -->
                        <a href="https://www.facebook.com/howdesign.creative">Facebook</a>
                    </small>
                </div>
            </div>
            <div class="right">
                <h5>
                    WORK WITH US!
                </h5>
                <small>
                    洞悉需求、完美體驗、是我們不變的設計哲學， 若您有同樣的信念 歡迎加入我們！
                </small>
                <a class="see_jobs" target="_blank" rel="noopener noreferrer" href="https://www.104.com.tw/company/1a2x6bj9ab">
                    <img src="@/assets/img/index/arrow.svg" alt="">
                    <h6 class="txt-bold">See jobs</h6>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'publicFooter',
    methods: {
        openMail() {
            window.dataLayer.push({
                'event': 'user_click_email'
            });
            window.open('mailto:service@howdesign.com.tw');
        },
        openPhone() {
            window.dataLayer.push({
                'event': 'user_click_phone'
            });
            window.open('tel:0223311966');
        },
        
    }
}
</script>

<style lang="scss">
#footer{
    width: 100%;
    padding: 6% 0 4%;
    position: relative;
    background-color: $color-white;
    &::before{
        content: '';
        width: 80%;
        height: 1px;
        margin: auto;
        display: block;
        background-color: #e8e8e8;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%)
    }
    .inner{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .left{
        width: 50%;
    }
    .right{
        width: 50%;
        max-width: 330px;
        small{
            margin-top: 20px;
            display: block;
            line-height: 2;
        }
        .see_jobs{
            margin-top: 40px;
            &:hover{
                img{
                    animation: arrow_h 1s linear infinite;
                }
            }
            img{
                margin-right: 20px;
            }
            img, h6{
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .loc_item{
        margin: 10px 0;
        display: block;
        cursor: pointer;
        span{
			white-space: nowrap;
            margin-right: 8px;
            display: inline-block;
        }
    }
    .allrights{
        margin-top: 40px;
        opacity: .75;
        small{
            margin: 10px 0;
            display: block;
            a{
                margin-right: 40px;
                font-size: 1em;
            }
        }
    }
}
@media screen and (max-width: 1023px){
    #footer{
        .left{
            order: 2;
            width: 100%;
        }
        .right{
            order: 1;
            width: 100%;
            margin-bottom: 30px;
        }
    }
}
@media screen and (max-width: 768px){
    #footer{
        &::before{
			width: 90%;
		}
    }
}

@media screen and (max-width: 480px){
    #footer{
        padding: 12% 0 6%;
        .allrights{
            small{
                display: flex;
                justify-content: space-between;
                a{
                    margin-right: 0;
                }
            }
        }
    }
}
</style>