import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
 
Vue.config.productionTip = false


// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)


// VueScrollmagic
import VueScrollmagic from 'vue-scrollmagic'
Vue.use(VueScrollmagic,{
  verical: true,
  globalSceneOptions: {},
  loglevel: 2,
  refreshInterval: 100
})


// VueSweetalert2
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)

//video.js
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')