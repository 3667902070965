<template>
    <section id="index_clients">
        <div class="inner w1200">
            <h2 class="text txt-center txt-bold">
                CLIENTS
            </h2> 
            <div class="itembox">
                <div class="item">
                    <img src="~@/assets/img/brand/b01.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b02.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b03.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b06.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b07.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b10.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b11.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b12.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b14.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b15.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b16.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b17.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b19.jpg" alt="">
                </div>
                <div class="item">
                    <img src="~@/assets/img/brand/b21.jpg" alt="">
                </div> 
				<div class="item">
                    <img src="~@/assets/img/brand/logo-01-cathy-life-insurance.jpg" alt="">
                </div> 
				<div class="item">
                    <img src="~@/assets/img/brand/logo-02-day-and-night.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-03-liteon.jpg" alt="">
                </div>
				<div class="item">
                    <img src="~@/assets/img/brand/b05.jpg" alt="">
                </div>
				<div class="item">
                    <img src="~@/assets/img/brand/logo-04-nutella.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-05-everfun-holiday.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-06-bat.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-07-ricola.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-08-foodpanda.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-09-whirlpool.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-10-innova.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-11-obstetrics.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-12-home-care.jpg" alt="">
                </div>     
				<div class="item">
                    <img src="~@/assets/img/brand/logo-13society-of-perinatology.jpg" alt="">
                </div> 
				<div class="item">
                    <img src="~@/assets/img/brand/logo-14-gamania.jpg" alt="">
                </div> 
				<div class="item">
                    <img src="~@/assets/img/brand/logo-15-datasystems.jpg" alt="">
                </div>
				<div class="item">
                    <img src="~@/assets/img/brand/logo-16-sentao.jpg" alt="">
                </div> 
				<div class="item">
                    <img src="~@/assets/img/brand/logo-17-sunflower.jpg" alt="">
                </div>     
            </div>
			<swiper class="swiperbox" :options="swiperOption">
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/b01.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b02.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b03.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b06.jpg" alt="">
						</div>
					</div>
				</swiperSlide>
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/b07.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b10.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b11.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b12.jpg" alt="">
						</div>						
					</div>
				</swiperSlide>	
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/b14.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b15.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b16.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b17.jpg" alt="">
						</div>						
					</div>
				</swiperSlide>	
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/b19.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b21.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-01-cathy-life-insurance.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-02-day-and-night.jpg" alt="">
						</div>						
					</div>
				</swiperSlide>	
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/logo-03-liteon.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/b05.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-04-nutella.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-05-everfun-holiday.jpg" alt="">
						</div>						
					</div>
				</swiperSlide>	
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/logo-06-bat.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-07-ricola.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-08-foodpanda.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-09-whirlpool.jpg" alt="">
						</div>						
					</div>
				</swiperSlide>
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/logo-10-innova.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-11-obstetrics.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-12-home-care.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-13society-of-perinatology.jpg" alt="">
						</div>						
					</div>
				</swiperSlide>
				<swiperSlide>
					<div class="swiperitem">
						<div class="item">
							<img src="~@/assets/img/brand/logo-14-gamania.jpg" alt="">
						</div> 
						<div class="item">
							<img src="~@/assets/img/brand/logo-15-datasystems.jpg" alt="">
						</div>
						<div class="item">
							<img src="~@/assets/img/brand/logo-16-sentao.jpg" alt="">
						</div> 
						<div class="item">
							<img src="~@/assets/img/brand/logo-17-sunflower.jpg" alt="">
						</div>  			
					</div>
				</swiperSlide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
        </div>        
    </section>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
export default {
    name: 'indexClients',
    data: function() {
       return {		
			swiperOption: {
				slidesPerView: 3,
				spaceBetween:0,
				breakpoints: {
					600: {
						slidesPerView: 2,
						spaceBetween: 0
					},
				},
				pagination: {
					el: '.swiper-pagination'
				},
			}
		}
	},
	components: {
		swiper,
		swiperSlide
	},	
}
</script>

<style lang="scss">

#index_clients{
    padding: 0 0 8%;
    .itembox{
        margin-top: 6%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item{
            width: 25%;
            margin: 2% 0;
            transition: .3s;
            transform-origin: center;
            img{
                transition: .3s;
                filter:grayscale(0%); 
            }
            &:hover{
                img{
                    filter:grayscale(0%);
                    transform: scale(1.1);
                }
            }
        }
	}
	.swiperbox{
		display: none;
		margin-top: 40px;
		padding-bottom: 40px;
		.item{
			text-align: center;
		}
		.swiper-pagination{
			
		}
		.swiper-pagination-bullet-active{
			background: $color-green;
		}
	}
}

@media screen and (max-width: 1023px){
    #index_clients{
		.itembox{
			display: none;
		}
		.swiperbox{
			display: block;
		}
    }
}

@media screen and (max-width: 500px){
    #index_clients{
		.swiperbox{
			.swiperitem{
				
			}
		}
    }
}

@media screen and (max-width: 480px){
    #index_clients{
        .text{
            text-align: left;
        }
    }
}

</style>