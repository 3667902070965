<template>
  <div id="contactus">
	<div class="contentfield w1200">
		 <div class="inner">
            <div class="title left">
                <h2 class="txt-bold">
                    CONTACT US
                </h2>
            </div>
            <div class="right">
                <p class="lh-l">
                   無論是品牌規劃、活動行銷、網站系統開發，<br>創意是最能打動人心及吸引消費者目光的利器，而這是我們最拿手的！
                </p>
                <!-- <a href="" class="to_about">
                    <h6 class="txt-bold">
                        <img src="@/assets/img/index/arrow.svg" alt="">
                       	Get in touch
                    </h6>
                </a> -->
            </div>
        </div>
		<swiper class="swiperbox" :options="swiperOption">
			<swiperSlide v-for="(item,index) in slidedata" :key="index">
				<div class="swiperitem" v-bind:style="{backgroundColor : blockColor[index%4]}">
					<div class="img" :style="slidedata[index].src">
						<h3 class="title">{{slidedata[index].title}}</h3>
						<div class="text">
							<div class="text_item" v-for="(item,index2) in slidedata[index].company" :key="index2">
								<img :src="slidedata[index].company[index2].logo" alt=""><br>
								<p class="item_text">{{slidedata[index].company[index2].name}}</p>
							</div>
							<!-- <br><br>
							<img src="@/assets/img/index/logo-06.png" alt=""><br>
							<span>怎漾設計創意有限公司</span> -->
						</div>
					</div>
				</div>
			</swiperSlide>			
			<div class="swiper-button-prev" slot="button-prev"></div>
		</swiper>
	</div>
  </div>
</template>
<script>
	import { swiper, swiperSlide } from 'vue-awesome-swiper'
	import 'swiper/dist/css/swiper.css'
	export default {
		name: 'IndexContactus',
		data() {
			return {
				blockColor : ['#E62234', '#0067FF', '#00D1A9', '#FFC000'],
				slidedata:[
					{
						src:{backgroundImage: `url(${require('@/assets/img/index/06_map_1.jpg')})`},
						title:"TAIPEI",
						company: [
							{
								name : '怎漾數位創意股份有限公司',
								logo : require('@/assets/img/logo_svg/HOWDIGITAL_LOGO.svg')
							},
							{
								name : '怎漾設計創意有限公司',
								logo : require('@/assets/img/logo_svg/HOWDESIGN_LOGO.svg')
							},							
						]
					},
					{
						src:{backgroundImage: `url(${require('@/assets/img/index/06_map_2.jpg')})`},
						title:"SHANGHAI",
						company: [
							{
								name : '上海新漾信息科技有限公司',
								logo : require('@/assets/img/logo_svg/HOWLINK_LOGO.svg')
							}						
						]	
					},
					{
						src:{backgroundImage: `url(${require('@/assets/img/index/06_map_3.jpg')})`},
						title:"CHENGDU",
						company: [
							{
								name : '怎漾集團',
								logo : require('@/assets/img/logo_svg/HOWGROUP_LOGO.svg')
							},					
						]
					},
					{
						src:{backgroundImage: `url(${require('@/assets/img/index/06_map_4.jpg')})`},
						title:"JAKARTA",
						company: [
							{
								name : '怎漾集團',
								logo : require('@/assets/img/logo_svg/HOWGROUP_LOGO.svg')
							}						
						]
					}
				],
				swiperOption: {
					slidesPerView: 4,
					spaceBetween:0,
					loop:true,
					navigation: {
						nextEl: '.swiper-button-prev'
					}
				}
			}
		},
		components: {
			swiper,
			swiperSlide
		},	
	}
</script>
<style lang="scss">
	#contactus{
		padding: 8% 0;
		width: 100%;
		overflow: hidden;
		.contentfield{
			.inner{
				padding-bottom: 8%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.left{
					width: 50%;
					.zn-slogan {
						margin-top: 10px;
						letter-spacing: 4.5px;
					} 
				}
				.right{
					width: 50%;
					.to_about{
						margin-top: 30px;
						img{
							margin-right: 10px;
						}
						&:hover{
							img{
								animation: arrow_h 1s infinite;
							}
						}
					}
				}
				@media screen and (max-width: 1023px){
					.title{
						text-align: center;
					}
					.left{
						width: 100%;
						margin-bottom: 40px;
					}
					.right{
						width: 100%;
					}
				}
				@media screen and (max-width: 480px){
					.title{
						text-align: left;
					}
				}
			}  
			.swiperbox{
				width: 150%;
				padding-top: 30px;
				.swiperitem{
					@media screen and (min-width: 1023px){
						&:hover{
							.img{
								transform: translateY(-30px);
								.title{
									opacity: 0;
								}
								.text{							
									transform: translateY(-10px);
								}
								&::before{
									opacity: 0.5;
								}
							}
						}						
					}				
					.img{
						width: 100.5%;
						padding-bottom: 110%;
						background-size: 100%;
						background-position: center;
						background-repeat: no-repeat;
						transition: transform 0.3s ease;
						position: relative;
						.title{
							position: absolute;
							top: 50%;
							left: 15%;
							transform: translateY(-50%);
							color: $color-white;
							font-weight: bold;
							transition: opacity 0.3s ease,top 0.3s ease;
						}
						.text{
							width: 100%;
							position: absolute;
							padding-left: 15%;
							bottom: 0;
							left: 0;
							color: $color-white;
							transform: translateY(110%);
							transition: 0.3s ease;
							.text_item{
								width: 100%;
								margin-bottom: 20px;
								// .item_text{
								// 	font-size: 16px;
								// }
							}
							img{
								width: auto;
								max-width: 55%;
								max-height: 70px;
							}
						}
						&::before{
							content: "";
							width: 100%;
							height: 100%;
							background-color: hsla(0, 0, 0, 0.6);
							position: absolute;
							top: 0;
							left: 0;
							transition: opacity 0.3s ease;
						}
					}
					
				}
				.swiper-button-prev{
					background: none;
					left: -30px;
					width: 60px;
					height: 60px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-image: url('~@/assets/img/index/arrow_2.svg');
				}
				@media screen and (max-width: 1023px){
					width: 170%;
					.swiper-slide-active{
						.swiperitem{
							.img{
								transform: translateY(-30px);
								.title{
									opacity: 1;
									top: 30%;
								}
								.text{							
									transform: translateY(-10px);
								}
								&::before{
									opacity: 0.5;
								}
							}
						}
					}
					.swiperitem{
						.img{
							.title{
								font-size: 26px;
							}
							.text{
								img{
									max-width: 120px;
								}
							}
						}
					}
				}
				@media screen and (max-width: 768px){
					width: 200%;
					.swiperitem{
						// &:hover{
						// 	.img{
						// 		.title{
						// 			top: 35%;
						// 		}
						// 	}
						// }					
						.img{
							.title{
								font-size: 22px;
							}
							.text{
								.item_text{
									font-size: 16px;
								}
							}
						}
					}
				}
				@media screen and (max-width: 580px){
					width: 250%;
					.swiperitem{
						// &:hover{
						// 	.img{
						// 		.title{
						// 			top: 32%;
						// 		}
						// 	}
						// }
						.img{
							.title{
								font-size: 20px;
							}
							.text{
								.item_text{
									font-size: 14px;
									-webkit-text-size-adjust:100%; -moz-text-size-adjust:100%; -ms-text-size-adjust:100%;
								}
							}
						}
					}
				}
				@media screen and (max-width: 480px){
					width: 300%;
					.swiperitem{
						.img{
							.title{
								font-size: 18px;
							}
						}
					}
				}
				@media screen and (max-width: 380px){
					width: 350%;
				}
			}
		}
		@media screen and (max-width: 480px){
			padding: 12% 0;
		}
	}
</style>