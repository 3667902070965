<template>
    <div class="home">
        <publicHeader/>
        <IndexTopArea/>
        <IndexGiants/>
        <IndexAds/>
        <IndexClients/>
        <IndexAbout/>
        <IndexContactus/>
    </div>
</template>

<script>
import publicHeader from '@/components/public/Header.vue'
import IndexTopArea from '@/components/page/index/IndexTopArea.vue'
import IndexGiants from '@/components/page/index/IndexGiants.vue'
import IndexAds from '@/components/page/index/IndexAds.vue'
import IndexClients from '@/components/page/index/IndexClients.vue'
import IndexContactus from '@/components/page/index/IndexContactus.vue'
import IndexAbout from '@/components/page/index/IndexAboutHow.vue'
export default {
    name: 'Home',
    components: {
        publicHeader,
        IndexTopArea,
        IndexGiants,
        IndexAds,
		IndexClients,
		IndexContactus,
        IndexAbout
    }
}
</script>

<style lang="scss">
    .home{
        position: relative;
    }
</style> 