<template>
    <section id="top_area" ref="top_area">
        <div class="inner w1200">
            <div class="left"  v-bind:class="[{active : startWord}]">
                <div class="itembox">
                    <h2 v-for="(item, i) in itemData" class="item txt-bold" v-bind:class="[{active : startWord}]">
                        <span v-for="(word, index) in item" v-bind:style="{transitionDelay: (0.55 + index * 0.05 + i* 0.5) +'s'}">{{word}}</span>
                    </h2>
                </div>
            </div>
            <div class="right">
                <h4 class="txt-bold">
                    WHERE ARE YOU<br>
                    <span v-bind:class="[{active : startWord}]">GOING ?</span>
                </h4>
                <div class="scrollArea" v-bind:class="[{active : startWord}]">
                    <div class="scroll_down" v-on:click="scrolltoNext()" v-bind:class="[{active : startWord}]">
                        <p>
                            Scroll down
                            <img src="../../../assets/img/index/arrow-s.svg" alt="">
                        </p>  
                    </div>
                    
                </div>
            </div>
        </div>

        <p id="top_bg" class="txt-white txt-bold" v-bind:style="{transform: 'translateX(-' + bgMove + '%)'}">
            HOW
        </p>
    </section>
</template>

<script>
export default {
    name: 'indexTopArea',
    data: function() {
        return {
            startWord: false,
            itemData: ['BRANDING', 'MARKETING', 'DESIGNING', 'CODING'],
            scrollDistence: window.scrollY,
            elHeight: Number,
            bgMove: Number
        }
    },
    methods: {
        listenScrollDistence: function(){ 
            this.scrollDistence = window.scrollY;
        },
        scrolltoNext: function(){
            window.scrollTo({
                top: this.$refs.top_area.offsetHeight,
                behavior: 'smooth'
            });
        }
    },
    watch: {
        scrollDistence: function(){
            if(this.scrollDistence/this.elHeight <= 1){
                this.bgMove = (this.scrollDistence/this.elHeight)*100/15;  
            }
        }
    },
    created: function(){
        window.addEventListener('scroll', this.listenScrollDistence, true);
    },
    mounted: function(){
        this.elHeight = this.$refs.top_area.offsetHeight;
        setTimeout(() => {
            this.startWord = true;
        }, 500);
    },
    destroyed: function(){
        window.removeEventListener('scroll', this.listenScrollDistence, true);
    }
}
</script>

<style scoped lang="scss">
#top_area{
    width: 100%;
    height: 100vh;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    .inner{
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
    }
    .left{
        position: relative;
        &::before{
            content: '';
            width: 1px;
            height: 30vh;
            margin-bottom: 7vh;
            background-color: #000;
            display: block;
            transform-origin: top;
            transform: scaleY(0);
            transition: .5s;
        }
        &.active{
            &::before{
                transform: scaleY(1);
            }
        }
        .item{
            position: relative;
            line-height: 1.5;
            &::after{
                content: '';
                width: 10px;
                height: 10px;
                display: inline-block;
                margin-left: 5px;
            }
            &:nth-child(1)::after{ 
                background-color: $color-red;  transition-delay: 1s; opacity: 0;
            }
            &:nth-child(2)::after{ 
                background-color: $color-yellow; transition-delay: 1.5s; opacity: 0;
            }
            &:nth-child(3)::after{ 
                background-color: $color-green; transition-delay: 2s; opacity: 0;
            }
            &:nth-child(4)::after{ 
                background-color: $color-blue; transition-delay: 2.35s; opacity: 0;
            }
            span{
                width: 0;
                opacity: 0;
                line-height: 1;
            }
            &.active{
                span{
                    width: auto;
                    opacity: 1;
                    // -webkit-animation: flip-in 0s 3s ease-in-out both;
                    // animation: flip-in 0s 3s ease-in-out both;
                    // display: inline-block;
                }
                &:nth-child(1)::after,
                &:nth-child(2)::after,
                &:nth-child(3)::after,
                &:nth-child(4)::after{ 
                    // opacity: 1;
                }
            }
        }
    }
    .right{
        h4{
            position: relative;
            span{
                position: relative;
                &::before{
                    content: '';
                    width: 100%;
                    height: 50%;
                    position: absolute;
                    bottom: -10%;
                    left: -5%;
                    background-color: $color-yellow;
                    opacity: .8;
                    z-index: -1;
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: .5s 2.5s;
                }
                &.active{
                    &::before{
                        content: '';
                        transform: scaleX(1);
                    }
                }
            }
        }
        .scrollArea{
            width: 100%;
            height: 55vh;
            margin-top: 7vh;
            border-left: 1px dotted #ccc;
            position: relative;
            transform-origin: top;
            transform: scaleY(0);
            transition: .5s 3s;
            &.active{
                transform: scaleY(1);
            }
            .scroll_down{
                width: 40px;
                padding: 10px;
                background-color: #f8f8f8;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translate(-50%, 10vh);
                white-space: nowrap;
                cursor: pointer;
                opacity: 0;
                transition: .3s 3.5s;
                display: block;
                p{
                    writing-mode: vertical-rl;
                }
                &.active{
                    opacity: 1;
                }
                img{
                    margin-top: 10px;
                    animation: arrow_v 3s infinite;
                }
            }
        }
    }
}
#top_bg{
    font-size: 36vw;
    line-height: .7;
    position: absolute;
    top: 10%;
    left: -8%;
    pointer-events: none;
    user-select: none;
    transition: 1s linear;
}

@keyframes flip-in {
    0% {
        width: 0;
        display: none;
        opacity: 0;
    }
    100% {
        width: auto;
        display: inline;
        opacity: 1;
    }
}
@media screen and (max-width: 1280px){
    #top_area{
        .left{
            &::before{
                content: '';
                height: 25vh;
            }
        }
        .right{
            .scrollArea{
                height: 45vh;
            }
        }
    }
}
@media screen and (max-width: 1023px){
    #top_area{
        .inner{
            transform: translateY(30px);
        }
        .left{
            width: 100%;
            &::before{
                content: '';
                height: 40vh;
            }
        }
        .right{
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            text-align: right;
            h4{
                display: inline-block;
                text-align: left;
            }
            .scrollArea{
                height: 58vh;
                border-right: 1px dotted #ccc;
                border-left: none;
                .scroll_down{
                    left: auto;
                    right: 0;
                    transform: translate(50%, 15px);
                }
            }
        }
    }
}
@media screen and (max-width: 768px){
    #top_bg{
        font-size: 55vw;
        top: 20%;
    }
}
@media screen and (max-width: 480px){
    #top_area{
        height: 510px;
        .inner{
            transform: translateY(0);
        }
        .left{
            &::before{
                content: '';
                margin-bottom: 30px;
                // height: 45vh;
                height: 190px;
            }
        }
        .right{
            .scrollArea{
                // height: 60vh;
                height: 285px;
            }
        }
    }
}
</style>