<template>
    <section id="header" v-bind:class="[{reverse : ifReverse}, {fixed : ifFixed}, {active : ifHanburger}]">
        <div class="inner">
            <router-link v-on:click.native="linkReset" class="hd_logo" to="/">
                <img v-if="ifReverse == true && ifFixed != true && ifHanburger != true" src="@/assets/img/howgroup-logo-w.svg" alt="">
                <img v-else src="@/assets/img/howgroup-logo.svg" alt="">
            </router-link>
            <ul class="menu">
                <li class="txt-bold">
                    <router-link v-on:click.native="linkReset" to="/about">About</router-link>
                </li>
                <li class="txt-bold">
                    <router-link v-on:click.native="linkReset" to="/services">Services</router-link>
                </li>
                <li class="txt-bold">
                    <router-link v-on:click.native="linkReset" to="/works">Work</router-link>
                </li>
                <li class="txt-bold">
                    <router-link v-on:click.native="linkReset" to="/contact">Contact</router-link>
                </li>
            </ul>
            <div class="hamburger" v-on:click="listenHanburger" v-bind:class="[{reverse : ifReverse}, {fixed : ifFixed}, {active : ifHanburger}]">
                <div></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'publicHeader',
    props: ['ifReverse'],
    data: function(){
        return{
            scrollDistence: window.scrollY,
            screenHeight: window.innerHeight,
            ifFixed: false,
            ifHanburger: false
        }
    },
    methods: {
        listenScrollDistence: function(){ 
            this.scrollDistence = window.scrollY;
        },
        listenHanburger: function(){
            if(this.ifHanburger == true){
                this.ifHanburger = false
            }else{
                this.ifHanburger = true
            }
        },
        linkReset: function(){
            window.scrollTo(0,0);
            this.ifHanburger = false;
        }
    },
    watch: {
        scrollDistence: function(){
            if(this.scrollDistence >= this.screenHeight){ this.ifFixed = true }
            else{ this.ifFixed = false }
        }
    },
    created: function(){
        window.addEventListener('scroll', this.listenScrollDistence, true);
    },
    destroyed: function(){
        window.removeEventListener('scroll', this.listenScrollDistence, true);
    }
}
</script>

<style lang="scss">
#header{
    width: 100%;
    padding: 20px 0;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    transition: .3s;
    &.reverse{
        color: #fff;
    }
    &.fixed,
    &.active{ 
        position: fixed; 
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0,0,0,.3);
        color: #000;
    }
    &.white_bg{ background-color: #fff; }
    .inner{
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hd_logo{
        width: 160px;
        img{
            vertical-align: middle;            
        }
    }
    .menu{
        li{
            margin: 0 35px;
            display: inline-block;
        }
    }
    .hamburger{
        width: 25px;
        height: 25px;
        position: relative;
        display: none;
        div{
            width: 100%;
            height: 3px;
            background-color: #000;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-2px);
        }
        &::before{
            content: '';
            width: 100%;
            height: 3px;
            background-color: #000;
            position: absolute;
            top: 2px;
            left: 0;
        }
        &::after{
            content: '';
            width: 100%;
            height: 3px;
            background-color: #000;
            position: absolute;
            bottom: 2px;
            left: 0;
        }
        &.reverse{
            div,
            &::before,
            &::after{
                background-color: #fff;
            }
        }
        &.fixed,
        &.active{
            div,
            &::before,
            &::after{
                background-color: #000;
            }
        }
    }
}
@media screen and (max-width: 1023px){
    #header{
        padding: 14px 0;
        .menu{
            z-index: -1;
        }
        &.active{
            .menu{
                transform: translateY(0);
            }
        }
        .menu{
            width: 100%;
            display: block;
            position: fixed;
            top: 53px;
            left: 0;
            background-color: #fff;
            transform: translateY(-200%);
            transition: .5s;
            box-shadow: 0 10px 5px rgba(0,0,0,.1);
            li{
                width: 100%;
                text-align: center;
                margin: auto;
                border-top: #ccc 1px solid;
                a{
                    width: 100%;
                    padding: 20px 0;
                }
            }
        }
        .hamburger{
            display: block;
        }
    }
}
</style>