<template>
    <section id="index_about" ref="index_about">
        <div class="inner w1200">
            <div class="title left">
                <h2 class="txt-bold">
                    ABOUT HOW
                </h2>
                <h5 class="zn-slogan txt-bold">
                    面面完美契合的創意魔方
                </h5>
            </div>
            <div class="right">
                <p class="lh-l">
                    最好的，永遠不會只有一個面向！<br> 
                    HOWGROUP怎漾集團，用先進科技加持不凡創意，建構全方位行銷效益。<br>
                    更多關於我們的信仰與DNA，等你探索啟動。
                </p>
                <router-link to="/about" class="to_about">
                    <h6 class="txt-bold">
                        <img src="@/assets/img/index/arrow.svg" alt="">
                        About us
                    </h6>
                </router-link>
            </div>
        </div>
        <canvas width="1000" height="650" ref="canvas"></canvas>
        <div class="phone_bg"></div>
    </section>
</template>

<script>
export default {
    name: 'indexAbout',
    data: function(){
        return {
            timer: 0
        }
    },
    methods: {
        makeAnimation: function(){
            let wave = function(x, y, t, amp){
                const p1 = 0.8   * (x + y) + t,
                    p2 = 0.6   * (x - y) - t,
                    p3 = 0.002 * (x*x - y*y) - t,
                    a1 = 0.25  * Math.cos(p1 / 20),
                    a2 = 0.125 * Math.cos(p2 / 20),
                    a3 = 1.0   * Math.cos(p3 / 100);
                
                return amp * (a1 + a2 + a3);
            };

            let projection = function(){
                const sx = 0,    sy = 0, sz = 100,
                        cx = 6000, cy = 0, cz = 2000;
                
                return function(x, y, z) {
                    const k = (sx - x) / (cx - x),
                            px = k * (cy - y) + y - sy,
                            py = k * (cz - z) + z - sz;
                    
                    return [px, py];
                }
            }();

            let draw = function(){
                const canvas = document.querySelector('canvas'),
                    ctx = canvas.getContext('2d'),
                    length    = 550,
                    width     = 1920,
                    amplitude = 290 / 2,
                    half_w    = canvas.width  / 2,
                    half_h    = canvas.height / 2,
                    start_y   = -width / 2,
                    end_y     =  width / 2,
                    start_x   = -length / 2,
                    end_x     =  length / 2,
                    step = 6;

                let time = 0, 
                    px, py,
                    x, y, z,
                    xmin = -half_h;
                
                ctx.transform(1, 0, 0, -1, half_w, half_h);
                ctx.fillStyle = 'hsl(255, 0%, 70%)';
                
                return function(){
                    ctx.clearRect(-half_w, -half_h, canvas.width, canvas.height);
                    
                    xmin = -half_h;
                    for(y = start_y; y <= end_y; y += step){
                        for(x = end_x; x >= start_x; x -= step){
                            z = wave(x, y, time, amplitude);
                            [px, py] = projection(x, y, z);
                            if(py > xmin){
                                ctx.fillRect(px, py, 1.3, 1.3);
                                xmin = py;
                            }
                        }
                        xmin = -half_h;
                    }
                    
                    time += 0.5;
                    // requestAnimationFrame(draw);
                };
                
            }();

            draw();

            // this.timer = setInterval(() => {
            //     draw();
            // }, 1);
        }
    },
    mounted: function(){
        this.makeAnimation();
    },
    // destroyed: function(){
    //     clearInterval(this.timer)    
    // }
}
</script>

<style scoped lang="scss">
#index_about{
    padding: 8% 0 12%;
    background-color: $color-light;
    // background-image: url('~@/assets/img/index/about.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
    overflow: hidden;
    canvas{
        position: absolute;
        left: 0;
        bottom: -12%;
        width: 100%;
        height: 70%;
    }
    .inner{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
    }
    .left{
        width: 50%;
        .zn-slogan {
            margin-top: 10px;
            letter-spacing: 4.5px;
        } 
    }
    .right{
        width: 50%;
        .to_about{
            margin-top: 30px;
            img{
                margin-right: 10px;
            }
            &:hover{
                img{
                    animation: arrow_h 1s infinite;
                }
            }
        }
    }
    .phone_bg{
        width: 100%;
        padding: 10%;
        background-image: url(~@/assets/img/index/curve.png);
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        display: none;
    }
}
@media screen and (max-width: 1023px){
    #index_about{
        canvas{
            display: none;
        }
        .phone_bg{
            display: block;
        }
    }
}
@media screen and (max-width: 768px){
    #index_about{
        .title{
            text-align: center;
        }
        .left{
            width: 100%;
            margin-bottom: 40px;
        }
        .right{
            width: 100%;
        }
    }
}
@media screen and (max-width: 480px){
    #index_about{
        padding: 12% 0 14%;
        .title{
            text-align: left;
        }
    }
}
</style>